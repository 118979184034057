'use client'

import { Button, Menu } from '@mantine/core'
import Image from 'next/image'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { Icon } from '~/components/icons/icon'
import { IconName } from '~/components/icons/sprite'
import { countries } from '~/data/countries'
import { defaultCountry } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'
import { activeQS } from '~/utils/qs'

export function HomeHeader() {
  const [qs] = useQS()
  const params = useParams<{ country: 'cameroon' | 'burundi' }>()

  return (
    <header className="flex w-full items-center justify-between py-6">
      <div className="flex items-center gap-3">
        <Link prefetch href={`/${params.country}${activeQS(qs)}`}>
          <Image
            src="/images/dwelinn-white.png"
            width={70}
            height={20}
            alt="Dwelinn"
            className="shrink-0"
          />
        </Link>
        <div className="h-6 w-px bg-white/10" />
        <div>
          <Menu>
            <Menu.Target>
              <Button
                size="sm"
                leftSection={
                  <Icon
                    name={countries[params.country || defaultCountry].flag}
                  />
                }
                rightSection={<Icon name="chevron-down" />}
                className="h-auto bg-transparent px-0 hover:bg-black/0 md:self-end"
              >
                {countries[params.country || defaultCountry].name}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {Object.values(countries).map(({ name, slug }, index) => {
                return (
                  <Menu.Item
                    key={index}
                    component={Link}
                    href={`/${slug}/${activeQS(qs)}`}
                  >
                    <div className="flex items-center gap-1">
                      <Icon name={`${slug}-flag` as IconName} />
                      <span>{name}</span>
                    </div>
                  </Menu.Item>
                )
              })}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <Button
        size="sm"
        className="gap-0 bg-transparent hover:bg-black/10"
        component={Link}
        href={`/${params.country}/login`}
      >
        My Reservation
      </Button>
    </header>
  )
}
