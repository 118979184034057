"use client";

import Image from "next/image";
import {
  HomeFooterThumbNails,
  HomeFooterThumbNailsMobile,
} from "./home_footer_thumbnails";

export function HomeFooter() {
  return (
    <footer className="z-10 flex flex-col gap-10 py-6">
      <HomeFooterThumbNails />
      <HomeFooterThumbNailsMobile />
      {/* Subfooter section */}
      <section className="flex flex-wrap items-center justify-between  text-white">
        <div className="flex flex-wrap items-center gap-2">
          <Image
            loading="lazy"
            src="/images/favicon.png"
            alt="dwelinn"
            width="25"
            height={20}
          />
          <div className="flex items-center gap-1">
            <span>&copy;</span>
            <p className="my-0">{new Date().getFullYear()}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <p className="my-0 text-sm font-semibold hover:cursor-pointer">
            <a
              href="https://www.leapa.co/terms"
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              Cookie Policy
            </a>
          </p>
          <p className="my-0 text-sm font-semibold hover:cursor-pointer">
            <a
              href="https://www.leapa.co/terms"
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              Terms & Privacy Policy
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
}
