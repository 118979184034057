import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HeroImages"] */ "/vercel/path0/src/app/[country]/(landing)/components/hero_images.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeFooter"] */ "/vercel/path0/src/app/[country]/(landing)/components/home_footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeHeader"] */ "/vercel/path0/src/app/[country]/(landing)/components/home_header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntroText"] */ "/vercel/path0/src/app/[country]/(landing)/components/intro_text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SlidingBox"] */ "/vercel/path0/src/app/[country]/(landing)/components/sliding_box.tsx");
