'use client'

import { Drawer } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { countries } from '~/data/countries'
import { SupportedCountries } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'

export function SlidingBox() {
  const [qs, setQS] = useQS()
  const params = useParams<{ country: SupportedCountries }>()
  const [, { close }] = useDisclosure(false)

  const { name, tagline, image, description } =
    countries[params.country].bestKnown[qs.box || 'drink']
  return (
    <Drawer
      offset={8}
      radius="lg"
      size="md"
      position="right"
      opened={Boolean(qs.box)}
      onClose={() => {
        close()
        setQS({ box: null })
      }}
    >
      <article className="flex flex-col justify-center gap-8 bg-white">
        <div className="flex flex-col gap-3">
          <h2 className="max-h-full text-3xl font-extrabold text-slate-800">
            {name}
          </h2>
          <h4 className="my-0 text-start text-xl text-slate-800">{tagline}</h4>
        </div>

        <Image
          src={image.src}
          blurDataURL={image.blurDataURL}
          width={521}
          height={364}
          placeholder="blur"
          alt={name}
          className="w-full rounded-2xl object-cover object-center"
        />

        <p className="text-lg leading-normal text-slate-700">{description}</p>
      </article>
    </Drawer>
  )
}
