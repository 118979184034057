'use client'

import { useInterval } from '@mantine/hooks'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { countries } from '~/data/countries'
import { defaultCountry, SupportedCountries } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'
import { cn } from '~/utils/css'

export function HeroImages() {
  const [qs, setQS] = useQS()

  const params = useParams<{ country: SupportedCountries }>()

  useInterval(
    () => {
      const image = qs.image + 1
      setQS({ image: image > 4 ? 1 : image })
    },
    15000,
    { autoInvoke: true }
  )

  return countries[params.country || defaultCountry].images.map(
    (image, index) => {
      return (
        <Image
          id={`${index}`}
          key={index}
          fill
          quality={100}
          priority
          decoding="async"
          loading="eager"
          alt={`hero${index + 1}`}
          src={image}
          placeholder="blur"
          className={cn(
            'absolute z-0 h-screen w-screen object-cover transition-all',
            {
              hidden: qs.image !== index + 1,
              block: qs.image === index + 1
            }
          )}
        />
      )
    }
  )
}
