'use client'

import { ActionIcon } from '@mantine/core'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { Icon } from '~/components/icons/icon'
import { countries } from '~/data/countries'
import { defaultCountry, type SupportedCountries } from '~/data/enums'
import { useQS } from '~/hooks/use_qs'
import { cn } from '~/utils/css'

export function HomeFooterThumbNails() {
  const [qs, setQS] = useQS()
  const params = useParams<{ country: SupportedCountries }>()

  return (
    <div className="hidden gap-6 self-end md:flex">
      {countries[params.country || defaultCountry].images.map(
        (image, index) => {
          return (
            <button
              name="image"
              value={index}
              key={index}
              className={cn(
                'ouline-none block h-[60px] w-[100px] rounded-lg ring-4 ring-transparent hover:cursor-pointer hover:ring-teal-600',
                {
                  'ring-teal-600': qs.image === index + 1,
                  'ring-transparent': qs.image !== index + 1
                }
              )}
              onClick={() => setQS({ image: index + 1 })}
            >
              <Image
                src={image}
                width={100}
                height={60}
                placeholder="blur"
                alt={`Hero thumbnail ${index}`}
                className="size-full rounded-lg object-cover"
              />
            </button>
          )
        }
      )}
    </div>
  )
}

export function HomeFooterThumbNailsMobile() {
  const [qs, setQS] = useQS()
  const images = countries[qs.country].images

  return (
    <div className="flex justify-center gap-6 md:hidden">
      <ActionIcon
        type="submit"
        size="xl"
        className="rounded-full bg-black/50 shadow-lg hover:bg-black/60"
        name="image"
        onClick={() =>
          setQS({
            image: qs.image === 1 ? images.length : qs.image - 1
          })
        }
      >
        <Icon name="chevron-left" size="lg" className="text-white/70" />
      </ActionIcon>

      <ActionIcon
        type="submit"
        size="xl"
        className="rounded-full bg-black/50 shadow-lg hover:bg-black/60"
        name="image"
        onClick={() => setQS({ image: qs.image === 4 ? 1 : qs.image + 1 })}
      >
        <Icon name="chevron-right" size="lg" className="text-white/70" />
      </ActionIcon>
    </div>
  )
}
